<template>
  <client-only>
    <div>
      <section v-if="colorsSet && !cookies.consent" class="position-relative on-top">
        <transition name="cc-banner">
          <div v-if="ready && colorsSet && !cookies.consent" class="position-fixed on-bottom animated fadeInUp bg-dark">
            <div class="container-fluid">
              <div class="row align-items-center">
                <div class="col-md-8 text-white pt-4">
                  <p>
                    {{ $t('site.cookies_banner') }}
                  </p>
                  <i18n path="site.cookies_banner_confidentiality" tag="p">
                    <template #confidentiality>
                      <nuxt-link class="text-lowercase link-2" :to="localePath({ name: 'confidentiality' })" target="_blank">
                        {{ $t('site.confidentiality') }}
                      </nuxt-link>
                    </template>
                  </i18n>
                </div>
                <div class="col-md-4 h-100 p-4">
                  <div class="d-flex flex-column justify-content-center align-items-center">
                    <b-button variant="outline-primary" class="cc-cta-btns my-2 py-2 px-5" @click="cookies.modal = true">
                      {{ $t('cookies.manageCookies') }}
                    </b-button>
                    <b-button variant="outline-primary" class="cc-cta-btns my-2 py-2 px-5" @click="setConsent({ declineAll: true })">
                      {{ $t('cookies.declineAll') }}
                    </b-button>
                    <b-button variant="outline-primary" class="cc-cta-btns my-2 py-2 px-5" @click="setConsent({ reload: false })">
                      {{ $t('cookies.acceptAll') }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <b-modal
          id="troov-consent-modal"
          :visible="cookies.modal"
          centered
          scrollable
          no-close-on-backdrop
          no-close-on-esc
          :title="$t('site.cookies_modal_title')"
          size="lg"
          @close="cookies.modal = false"
        >
          <b-alert :show="!saved" variant="info">
            {{ $t('cookies.unsaved') }}
          </b-alert>
          <p>{{ $t('site.cookies_modal_description') }}</p>

          <template v-for="type in ['necessary', 'optional']">
            <h4 v-if="cookies[type] && cookies[type].length > 0" :key="type.id" class="bg-light mb-2 mt-4">
              {{ $t('cookies.' + type) }}
            </h4>
            <slot v-if="cookies[type] && cookies[type].length > 0" name="cookielist">
              <b-list-group v-for="cookie in getCompatibleCookies(cookies[type])" :key="cookie.id">
                <b-list-group-item class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1">
                      {{ getName(cookie.name) }}
                    </h6>
                    <b-form-checkbox
                      v-if="type === 'necessary' && cookie.name !== 'functional'"
                      :id="getCookieFirstName(cookie.name)"
                      :checked="true"
                      disabled
                      switch
                      size="lg"
                    >
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-else
                      :id="getCookieFirstName(cookie.name)"
                      :checked="
                        cookies.enabledList.includes(cookie.identifier || cookies.slugify(getCookieFirstName(cookie.name))) ||
                        (cookies.get('cc_consent').length === 0 && cookie.initialState === true)
                      "
                      switch
                      size="lg"
                      @change="toogleCookie(cookie)"
                    >
                    </b-form-checkbox>
                  </div>

                  <p class="mb-1 font-size-14">
                    {{ getDescription(cookie.description) }}
                  </p>

                  <small v-if="cookie.cookies">
                    <slot name="cookie" v-bind="{ config: cookie }">
                      <ul>
                        <li v-for="item in cookie.cookies" :key="item.id" v-html="item" />
                      </ul>
                    </slot>
                  </small>
                  <hr />
                </b-list-group-item>
              </b-list-group>
            </slot>
          </template>

          <template #modal-footer>
            <div class="d-flex justify-content-center align-items-center">
              <div>
                <b-button variant="outline-primary" class="mx-2" @click="setConsent({ type: 'partial' })">
                  {{ $t('cookies.save') }}
                </b-button>

                <b-button variant="outline-primary" class="mx-2" @click="setConsent({ declineAll: true })">
                  {{ $t('cookies.declineAll') }}
                </b-button>

                <b-button variant="primary" class="mx-2" @click="setConsent">
                  {{ $t('cookies.acceptAll') }}
                </b-button>
              </div>
            </div>
          </template>
        </b-modal>
      </section>
    </div>
  </client-only>
</template>

<script>
export default {
  name: 'ConsentBanner',
  data() {
    return {
      saved: true,
      ready: false,
      colorsSet: false,
      cookies: this.$cookies
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    expirationDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return date.toUTCString();
    },
    optionalCookies() {
      return this.cookies.optional || [];
    },
    iframeMode() {
      return this.$router.currentRoute.query.iframe === 'no-header-footer';
    }
  },
  beforeMount() {
    if (process.browser && this.cookies.colors) {
      let key = null;
      const variables = {};
      for (key in this.cookies.colors) {
        const k = key.toLowerCase().includes('unactive') ? key.replace(/Unactive/g, 'Inactive') : key;
        variables[`c-control-${k}`] = `${this.cookies.colors[key]}`;
      }
    }
    if (!this.cookies.get('cc_consent') || this.cookies.get('cc_consent').length === 0) {
      this.optionalCookies.forEach((c) => {
        if (c.initialState === true) {
          this.cookies.enabledList.push(c.identifier || this.cookies.slugify(this.getCookieFirstName(c.name)));
        }
      });
    }
    this.colorsSet = true;
  },
  mounted() {
    this.ready = true;
  },
  methods: {
    toogleCookie(cookie) {
      const cookieName = cookie.identifier || this.cookies.slugify(this.getCookieFirstName(cookie.name));
      if (this.saved) this.saved = false;
      if (!this.cookies.enabledList.includes(cookieName)) this.cookies.enabledList.push(cookieName);
      else this.cookies.enabledList.splice(this.cookies.enabledList.indexOf(cookieName), 1);
    },
    setConsent({ type, consent = true, reload = true, declineAll = false }) {
      this.cookies.set({ name: 'cc_consent', value: consent, expires: this.expirationDate });
      const enabledCookies = declineAll
        ? []
        : type === 'partial' && consent
        ? this.cookies.enabledList
        : [...this.optionalCookies.map((c) => c.identifier || this.cookies.slugify(this.getCookieFirstName(c.name)))];
      this.cookies.set({ name: 'cc_enabled_cookies', value: consent ? enabledCookies.join(',') : '', expires: this.expirationDate });
      if (!reload) {
        this.cookies.setConsent();
        this.$cookies.modal = false;
      } else window.location.reload(true);
    },
    getDescription(description) {
      if (typeof description === 'string') return ` ${this.cookies.dashInDescription !== false ? '- ' : ''} ${description}`;
      else if (description[this.locale]) return ` ${this.cookies.dashInDescription !== false ? '- ' : ''} ${description[this.locale]}`;
      return '';
    },
    getName(name) {
      return name === 'functional'
        ? this.cookies.text.functional
        : typeof name === 'string'
        ? name
        : name[this.locale]
        ? name[this.locale]
        : name[Object.keys(name)[0]];
    },
    getCookieFirstName(name) {
      return typeof name === 'string' ? name : name[Object.keys(name)[0]];
    },
    getCompatibleCookies(list = []) {
      return list.filter((el) => el.layout && el.layout.includes('gouv'));
    }
  }
};
</script>

<style scoped lang="scss">
.on-top {
  z-index: 1040;
  button {
    transition: background-color 200ms, color 200ms;
  }
}
.on-bottom {
  left: 0;
  right: 0;
  bottom: 0;
}
.cc-banner-enter,
.cc-banner-leave-to {
  transform: translateY(100%);
}
.cc-banner-enter-active,
.cc-banner-leave-active {
  transition: transform 0.25s;
}
.cc-cta-btns {
  min-width: 200px;
}
</style>
