<template>
  <div class="fr-header__brand fr-enlarge-link">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'NavGouvBrandGroup'
};
</script>
