<template>
  <div class="fr-header__brand-top">
    <div class="fr-header__logo">
      <p class="fr-logo">
        MINISTÈRE
        <br />DE L'EUROPE <br />ET DES AFFAIRES <br />ÉTRANGÈRES
      </p>
    </div>
    <div class="fr-header__navbar">
      <language-switcher :show-label="false" />
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from '~/components/site/language-switcher';
export default {
  name: 'NavGouvBrand',
  components: { LanguageSwitcher },
  props: {
    service: Object
  }
};
</script>
<style scoped>
.fr-header__logo > div {
  max-width: 160px;
  max-height: 80px;
}

.header-img {
  width: auto;
  max-height: 100px;
  max-width: 160px;
}

@media (max-width: 767px) {
  .fr-header__logo > div {
    max-width: 120px;
    max-height: 80px;
  }
  .header-img {
    max-width: 120px;
  }
}
</style>
