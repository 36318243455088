<template>
  <header role="banner" class="fr-header">
    <div class="fr-header__body">
      <div class="fr-container">
        <div class="fr-header__body-row">
          <slot></slot>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'NavGouvHeader'
};
</script>
