<template>
  <b-dropdown
    variant="black"
    toggle-class="header-item"
    right
    lazy
    :title="`${$t('rgaa.actual_language')} ${$t('language.language').toLowerCase()} ${showLabelText(true)}`"
  >
    <template #button-content>
      <b-img class="lazyload" :src="`/images/flags/x24/${$i18n.locale}.png`" alt="" width="24" />
      {{ showLabelText(showLabel) }}
    </template>

    <!-- item-->
    <b-dropdown-item-button v-for="locale in availableLocales" :key="locale.code" @click.prevent="updateLang(locale.code)">
      <b-img-lazy :src="`/images/flags/x24/${locale.code}.png`" alt="" class="mr-1 lazyload" width="24" />
      <span class="align-middle">{{ locale.name }}</span>
    </b-dropdown-item-button>
  </b-dropdown>
</template>
<script>
import LanguageSwitcherMixin from '~/components/site/language-switcher/language-switcher-mixin';

export default {
  name: 'LanguageSwitcher',
  mixins: [LanguageSwitcherMixin],
  props: {
    showLabel: { type: Boolean, default: true }
  },
  methods: {
    showLabelText(result) {
      if (result === true) {
        switch (this.$i18n.locale) {
          case 'en':
            return this.$t('language.english');
          case 'de':
            return this.$t('language.germany');
          case 'fr':
            return this.$t('language.french');
          case 'zh':
            return this.$t('language.chinese');
          case 'ar':
            return this.$t('language.arabic');
          case 'ru':
            return this.$t('language.russian');
          case 'es':
            return this.$t('language.espagnol');
          case 'it':
            return this.$t('language.italian');
          default:
            return '';
        }
      }
    }
  }
};
</script>
