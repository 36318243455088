<template>
  <span v-if="false" />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'StructuredDataGraph',
  head() {
    return {
      script: [
        {
          type: 'application/ld+json',
          hid: 'ldjson-schema-graph',
          json: this.jsonLdSchema
        }
      ]
    };
  },
  computed: {
    ...mapState({
      graphs: (state) => state.site.jsonLd
    }),
    graph() {
      const graph = [];
      for (const graphKey in this.graphs) {
        if (this.graphs[graphKey]) {
          graph.push(this.graphs[graphKey]);
        }
      }
      return graph;
    },
    jsonLdSchema() {
      return {
        '@context': 'http://schema.org',
        '@graph': this.graph
      };
    }
  }
};
</script>
