<template>
  <!-- pied de page avec logo opérateur -->
  <div class="fr-footer-upper-line">
    <div class="fr-footer__brand-linkp d-flex justify-content-around">
      <p class="fr-footer-text font-weight-bold">{{ $t('common.solution_appointment') }}</p>
      <a href="https://troov.com" class="footer_link_logo" :aria-label="$t('gouv_footer.logo_redirect')">
        <img class="fr-footer__logo" src="/images/gouv/logo-dark.png" alt="Troov" />
      </a>
    </div>
    <footer id="footer" class="fr-footer" role="contentinfo">
      <div class="fr-container">
        <div class="fr-footer__body fr-footer__body--operator">
          <div class="fr-footer__brand fr-enlarge-link">
            <p class="fr-logo" title="république française">
              MINISTÈRE
              <br />DE L'EUROPE <br />ET DES AFFAIRES <br />ÉTRANGÈRES
            </p>
          </div>
          <div class="fr-footer__content">
            <ul class="fr-footer__content-list">
              <li class="fr-footer__content-item">
                <a
                  class="fr-footer__content-link"
                  href="https://legifrance.gouv.fr"
                  target="_blank"
                  :aria-label="$t('gouv_footer.legifrance_redirect')"
                  >legifrance.gouv.fr</a
                >
              </li>
              <li class="fr-footer__content-item">
                <a class="fr-footer__content-link" href="https://gouvernement.fr" target="_blank" :aria-label="$t('gouv_footer.government_redirect')"
                  >gouvernement.fr</a
                >
              </li>
              <li class="fr-footer__content-item">
                <a
                  class="fr-footer__content-link"
                  href="https://service-public.fr"
                  target="_blank"
                  :aria-label="$t('gouv_footer.public_service_redirect')"
                  >service-public.fr</a
                >
              </li>
              <li class="fr-footer__content-item">
                <a class="fr-footer__content-link" href="https://data.gouv.fr" target="_blank" :aria-label="$t('gouv_footer.data_gouv_redirect')"
                  >data.gouv.fr</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="fr-footer__bottom">
          <ul class="fr-footer__bottom-list">
            <li class="fr-footer__bottom-item">
              <router-link
                class="fr-footer__bottom-link"
                :aria-label="$t('gouv_footer.accessibility_certificate')"
                :to="localePath('accessibility-certificate')"
              >
                {{ $t('common.declare_accessibility') }}
              </router-link>
            </li>
            <li class="fr-footer__bottom-item">
              <router-link class="fr-footer__bottom-link" :aria-label="$t('gouv_footer.legal_mentions')" :to="localePath('legals')">{{
                $t('common.legals')
              }}</router-link>
            </li>
            <li class="fr-footer__bottom-item">
              <nuxt-link class="fr-footer__bottom-link" :aria-label="$t('gouv_footer.privacy_policy')" :to="localePath('confidentiality')">{{
                $t('common.personal_data')
              }}</nuxt-link>
            </li>
            <li class="fr-footer__bottom-item">
              <router-link class="fr-footer__bottom-link" :aria-label="$t('gouv_footer.terms_of_use')" :to="localePath('cgu')">{{
                $t('common.general_terms_of_use')
              }}</router-link>
            </li>
          </ul>
          <div class="fr-footer__bottom-copy">
            <i18n path="site.licence" tag="p">
              <template #etalab>
                <a href="https://github.com/etalab/licence-ouverte/blob/master/LO.md" :aria-label="$t('gouv_footer.etalab_license')"target="_blank">
                  {{ $t('site.etalab') }}
                </a>
              </template>
            </i18n>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'GouvFooter'
};
</script>
<style scoped>
.footer_link_logo {
  background: none;
}
.fr-footer__logo {
  width: 160px;
}
.fr-footer__brand .fr-logo {
  font-size: 1rem !important;
}
</style>
