<template>
  <span v-if="false" />
</template>
<script>
export default {
  name: 'StructuredDataCorporation',
  computed: {
    ldJson() {
      return {
        '@context': 'http://www.schema.org',
        '@type': 'Corporation',
        name: 'Troov',
        legalName: 'Troov S.A.S',
        email: 'contact@diplomatie.gouv.fr',
        url: this.$config.API_HOST,
        logo: this.$config.API_HOST + '/images/brand/logo_troov.png',
        image: {
          '@type': 'ImageObject',
          '@id': this.$config.API_HOST + '/#logo',
          url: this.$config.API_HOST + '/images/brand/logo_troov.png',
          caption: 'logo'
        },
        description: this.$t('seo.processItemDescription'),
        address: {
          '@type': 'PostalAddress',
          streetAddress: '110 Boulevard Bineau',
          addressLocality: 'Neuilly Sur Seine',
          postalCode: '92200',
          addressCountry: 'France'
        }
        // geo: {
        //   '@type': 'GeoCoordinates',
        //   latitude: '48.862725',
        //   longitude: '2.287592'
        // },
        // openingHoursSpecification: [
        //   {
        //     '@type': 'OpeningHoursSpecification',
        //     dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        //     opens: '00:00',
        //     closes: '23:59'
        //   }
        // ]
        /*
        sameAs: [
          'https://www.facebook.com/Troov.co',
          'https://twitter.com/troov_com',
          'https://www.instagram.com/troov_com/',
          'https://www.linkedin.com/company/troov-co',
          'https://www.youtube.com/channel/UCKNx7JD8whLwvWFgZCHQCvw/'
        ]
        */
      };
    }
  },
  created() {
    this.$store.commit('site/UPDATE_JSON_LD', { key: 'corporation', value: this.ldJson });
  },
  beforeDestroy() {
    // no needed ?
    this.$store.commit('site/UPDATE_JSON_LD', { key: 'corporation', value: null });
  }
};
</script>
