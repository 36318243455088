<template>
  <nav-gouv-header>
    <nav-gouv-brand-group>
      <nav-gouv-brand :service="service" />
      <nav-gouv-service-name v-if="!!service" :title="service.name || ''" subtitle="" />
    </nav-gouv-brand-group>
    <div class="fr-header__tools">
      <div class="d-none header-item">
        <b-img src="/images/gouv/logo-dark.png" alt="" width="auto" height="40" />
      </div>
      <div class="d-none d-sm-none d-md-inline">
        <language-switcher :show-label="false" />
      </div>
    </div>
  </nav-gouv-header>
</template>

<script>
import NavGouvBrand from '~/components/ui/gouv/nav-bar/nav-gouv-brand';
import NavGouvServiceName from '~/components/ui/gouv/nav-bar/nav-gouv-service-name';
import NavGouvHeader from '~/components/ui/gouv/nav-bar/nav-gouv-header';
import NavGouvBrandGroup from '~/components/ui/gouv/nav-bar/nav-gouv-brand-group';
import LanguageSwitcher from '~/components/site/language-switcher';

export default {
  name: 'GouvHeader',
  components: { LanguageSwitcher, NavGouvBrandGroup, NavGouvHeader, NavGouvServiceName, NavGouvBrand },
  data() {
    return {
      service: null
    };
  },
  mounted() {
    this.$root.$on('root-service-name', this.handleServiceName);
  },
  beforeDestroy() {
    this.$root.$off('root-service-name', this.handleServiceName);
  },
  methods: {
    handleServiceName(service) {
      this.service = service;
    }
  }
};
</script>
