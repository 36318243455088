import { mapState } from 'vuex';

const LanguageSwitcherMixin = {
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
    allLocales() {
      return this.$i18n.locales;
    }
  },
  methods: {
    getLocaleLink(lang) {
      return this.switchLocalePath(lang);
    },
    updateLang(lang) {
      if (this.currentPage === 'faq' && this.$route.params.categorySlug) {
        return this.$store
          .dispatch('page/getLangUrl', {
            type: 'faq',
            categorySlug: this.$route.params.categorySlug,
            summarySlug: this.$route.params.summarySlug,
            lang
          })
          .then((page) => {
            return this.$router.replace(`/${lang}/faq/${page.categorySlug}/${page.summarySlug}`);
          });
      }
      if (this.user && this.user.email) {
        this.$store
          .dispatch('user/updateUser', {
            language: lang
          })
          .catch()
          .then(() => window.location.assign(this.getLocaleLink(lang)));
      } else {
        this.$router.replace({ path: this.getLocaleLink(lang) });
      }
    }
  }
};

export default LanguageSwitcherMixin;
