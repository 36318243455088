<template>
  <span v-if="false" />
</template>

<script>
export default {
  name: 'StructuredDataOrganisation',
  computed: {
    ldJson() {
      return {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Troov',
        legalName: 'Troov S.A.S',
        email: 'contact@diplomatie.gouv.fr',
        slogan: this.$t('seo.solution_rdv_consulate'),
        url: this.$config.API_HOST,
        logo: this.$config.API_HOST + '/images/brand/logo_troov.png',
        foundingDate: '2018',
        description: this.$t('seo.solution_rdv_consulate'),
        // telephone: '[+561-526-8457]',
        founders: [
          {
            '@type': 'Person',
            name: 'Aurélie Toubol'
          },
          {
            '@type': 'Person',
            name: 'Grégoire Rey-Brot'
          },
          {
            '@type': 'Person',
            name: 'Ibrahim Fofana'
          }
        ],
        address: {
          '@type': 'PostalAddress',
          streetAddress: '110 Boulevard Bineau',
          addressLocality: 'Neuilly Sur Seine',
          postalCode: '92200',
          addressCountry: 'France'
        },
        // contactPoint might be deprecated ?
        contactPoint: [
          {
            '@type': 'ContactPoint',
            contactType: 'customer service',
            // telephone: '[+561-526-8457]',
            email: 'contact@diplomatie.gouv.fr'
          },
          {
            '@type': 'ContactPoint',
            contactType: 'technical support',
            // telephone: '[+561-526-8457]',
            email: 'contact@diplomatie.gouv.fr'
          }
        ]
        // openingHoursSpecification: [
        //   {
        //     '@type': 'OpeningHoursSpecification',
        //     dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        //     opens: '00:00',
        //     closes: '23:59'
        //   }
        // ],
        // geo: {
        //   '@type': 'GeoCoordinates',
        //   latitude: '48.862725',
        //   longitude: '2.287592'
        // },
        // sameAs: [
        //   'https://www.facebook.com/Troov.co',
        //   'https://twitter.com/troov_com',
        //   'https://www.instagram.com/troov_com/',
        //   'https://www.linkedin.com/company/troov-co',
        //   'https://www.youtube.com/channel/UCKNx7JD8whLwvWFgZCHQCvw/'
        // ]
      };
    }
  },
  created() {
    this.$store.commit('site/UPDATE_JSON_LD', { key: 'organization', value: this.ldJson });
  },
  beforeDestroy() {
    // no needed ?
    this.$store.commit('site/UPDATE_JSON_LD', { key: 'organization', value: null });
  }
};
</script>
