<template>
  <span v-if="false" />
</template>

<script>
export default {
  name: 'StructuredDataWebsite',
  computed: {
    ldJson() {
      return {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: 'consulat.gouv.fr - ' + this.$t('seo.solution_rdv_consulate'),
        url: this.$config.HOST_NUXT,
        copyrightHolder: 'Troov S.A.S',
        copyrightYear: new Date().getFullYear(),
        // sameAs: [
        //   'https://www.facebook.com/Troov.co',
        //   'https://twitter.com/troov_com',
        //   'https://www.instagram.com/troov_com/',
        //   'https://www.linkedin.com/company/troov-co',
        //   'https://www.youtube.com/channel/UCKNx7JD8whLwvWFgZCHQCvw/'
        // ]
      };
    }
  },
  created() {
    this.$store.commit('site/UPDATE_JSON_LD', { key: 'website', value: this.ldJson });
  },
  destroyed() {
    this.$store.commit('site/UPDATE_JSON_LD', { key: 'website', value: null });
  }
};
</script>
