<template>
  <div class="fr-header__service">
    <p v-if="!!title" class="fr-header__service-title">{{ title }}</p>
    <!--    <p v-if="!!subtitle" class="fr-header__service-tagline">{{ subtitle }}</p>-->
  </div>
</template>

<script>
export default {
  name: 'NavGouvServiceName',
  props: {
    title: String,
    subtitle: String
  }
};
</script>
